import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import get from 'lodash/get';
import merge from 'lodash/merge';
import PropTypes from 'prop-types';
import { RenderHTML } from '@dmm/react-common-components';

import { generateBlogPath } from '../../../../utils/urlHelpers/blog';

import BreadCrumb from '../../../../components/BreadCrumb';
import Pagination from '../../../../components/Pagination';
import Spinner from '../../../../components/Spinner';
import BlogNavbar from '../BlogNavbar';
import CategorySelector from '../CategorySelector';
import { PortalConfigContext } from '../../../../config/portal';
import { TRACKING_EVENT_LABEL } from '../../../../constants/dataLayer';
import Meta from '../../../../components/Meta';
import { getCanonical, getSeoDataItems, getMetaTitle, getMetaDescription, getBannerText } from '../../../../utils/seo';
import { getAssetUrl } from '../../../../utils/commonHelper';

import AdProvider from '../../../../components/Ads/AdProvider';
import { adsConfig } from './resources/adsConfig';
import adParams from './resources/adParams.json';
import { getBreakpoint, BREAKPOINTS } from '../../../../utils/commonHelper';
import { getPortalName } from '../../../../utils/language';

import './styles.css';
import { getMessages } from '../../../../tppServices/translations/messages';
import { AdDisplayer } from '../../../../components/Ads/AdDisplayer/AdDisplayer';
import { getPageAdsData } from '../../../../utils/ads/adsUtils';
import { Cookies } from 'react-cookie';

class BlogHome extends PureComponent {

  state = {
    breakpoint: 'desktop'
  }

  resizeHandler = () => {
    this.setState({
      breakpoint: getBreakpoint()
    });
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeHandler);
    this.resizeHandler();
  }

  getBannerTextSize = (category) => {
    const bannerText = getBannerText(category);
    const bannerTextLength = bannerText.length;
    const baseTextLength = 8;
    const baseMinTextSize = 36;
    const baseMaxTextSize = 172;
    const minTextSize = (baseMinTextSize * Math.min(1, ( 1 / (bannerTextLength / baseTextLength)))).toFixed(0);
    const maxTextSize = (baseMaxTextSize * Math.min(1, ( 1 / (bannerTextLength / baseTextLength)))).toFixed(0);
    const minViewportWidth = 300;
    const maxViewportWidth = 1440;

    return `min(${maxTextSize}px, 
      calc(${minTextSize}px + 
        (${maxTextSize} - ${minTextSize}) * 
        ((100vw - ${minViewportWidth}px) / (${maxViewportWidth} - ${minViewportWidth}))
      )
    )`;
  }

  getBannerImg = (banner) => {
    const category = get(this.props.params, 'category', '');
    const bannerImage = get(banner, category ? category : 'home', {});
    const imageParams = {
      srcSet: `${getAssetUrl(bannerImage.mobile)} 500w,
               ${getAssetUrl(bannerImage.tablet)} 968w, 
               ${getAssetUrl(bannerImage.desktop)} 2304w`,
      sizes: '(max-width: 500px) 500px, (max-width: 968px) 968px, (min-width: 968px) 1256px',
      src: getAssetUrl(bannerImage.desktop),
      alt: ''
    };

    return <img {...imageParams} />;
  }

  getArticleURL = (article) => generateBlogPath({}, {article: article.name})

  getArticleFeaturedImageURL = (article) => {
    const baseURL = get(article.content, 'featuredImage', '');
    const parsedURL = baseURL.replace('{{baseUrl}}',article.blogUrl);

    return parsedURL;
  }

  getBreadcrumbs = (rootBreadcrumb, t) => {
    const messages = getMessages();
    const breadcrumbs = [
      {
        title: rootBreadcrumb,
        link: '/'
      },{
        title: t(messages.blogRoot),
      }
    ];

    const category = get(this.props.params, 'category', '');
    if (category) {
      breadcrumbs.push({ title: t(messages.blog.categories[category]) });
      breadcrumbs[1].link = generateBlogPath({},{});
    }

    return breadcrumbs;
  };

  getSpinner = (showSpinner) => showSpinner
    ? <div className="spinner-container">
      <Spinner hidden={!showSpinner} />
    </div>
    : null

  getArticles = (articles) => articles && articles.records ? (
    <div className="articles-container">
      {articles.records.map((article,index) => {
        const loading = index >= 3 ? {loading: 'lazy'} : {};
        return (<article key={index} id={article.id} >
          <a href={this.getArticleURL(article)} >
            <div className="image-container">
              <img src={this.getArticleFeaturedImageURL(article)} alt="" {...loading}/>
            </div>
            <h2>{article.title}</h2>
            <RenderHTML html={article.content.excerpt}/>
          </a>
        </article>);
      })}
    </div>
  ) : null

  getAdsColumn = (newPageAdsConfig) => (
    <>
      <AdDisplayer
        newAdsConfig={newPageAdsConfig}
        adSlot={'box-1'}
        googleAdParams={adParams.box1Params} />
      <AdDisplayer
        newAdsConfig={newPageAdsConfig}
        adSlot={'box-2'}
        googleAdParams={adParams.box2Params} />
      <AdDisplayer
        newAdsConfig={newPageAdsConfig}
        adSlot={'box-3'}
        googleAdParams={adParams.box3Params} />
    </>
  )

  render() {
    const {
      articles,
      pageHorizon,
      currentPage,
      maxPages,
      getPaginationUrl,
      onPaginationChange
    } = this.props;
    const { isLoading, intl: { formatMessage: t } } = this.props;
    const context = get(this, 'context', {});
    const pagesContext = get(context, 'pages', {});
    const blogContext = get(pagesContext, 'blog', {});
    const categories = get(blogContext, 'categories', []);
    const homeContext = get(blogContext, 'home', {});
    const banner = get(homeContext, 'banner', '');
    const breadcrumbs = get(homeContext, 'breadcrumbs', '');
    const category = get(this.props.params, 'category', '');
    const seoInfo = getSeoDataItems(this.props.seoContent, category ? category : 'homepage');
    const portal = getPortalName(this.context);

    const url = this.props.location.pathname;
    const categoryContext = categories.filter(context => {
      return context.name === category;
    });
    const pageAdsConfig = merge(categoryContext.length > 0 ?
      categoryContext[0].adsConfig : homeContext.adsConfig, adsConfig);

    const paginationProps = {
      current: currentPage,
      getUrl: getPaginationUrl,
      onPageClick: onPaginationChange,
      pageHorizon: pageHorizon,
      total: maxPages,
      nextPageLabel: TRACKING_EVENT_LABEL.PAGINATION.next,
      previousPageLabel: TRACKING_EVENT_LABEL.PAGINATION.previous,
    };

    const newPageAdsConfig = getPageAdsData(blogContext.newAdsConfig.adZones, this.props.adsData);

    return (
      <>
        <AdProvider url={url} isWorking={this.props.isLoading} targeting={{ page: `blog-${category ? category : 'home'}` }} adsConfig={pageAdsConfig}>
          <Meta
            canonical={getCanonical(url)}
            title={seoInfo.length > 0 ? seoInfo[0].title : getMetaTitle(category ? `blog.categoryPageSEO.${category}` : 'blog.homePageSEO', portal)}
            description={seoInfo.length > 0 ? seoInfo[0].text : getMetaDescription(category ? `blog.categoryPageSEO.${category}` : 'blog.homePageSEO', portal)}
          />
          <div className="blog-home">
            <BlogNavbar categories={categories} {...this.props}/>
            <BreadCrumb items={this.getBreadcrumbs(breadcrumbs, t)} />
            <div className="body">
              <figure>
                <div className="image-container">
                  {this.getBannerImg(banner)}
                  <h1 style={{fontSize: this.getBannerTextSize(category)}}>
                    {getBannerText(category).toUpperCase()}
                  </h1>
                </div>
              </figure>
              <div className={`content${isLoading ? ' loading' : ''}`}>
                {this.getSpinner(isLoading)}
                {this.getArticles(articles)}
                { this.state.breakpoint === BREAKPOINTS.desktop && <div className="ads-column" >{this.getAdsColumn(newPageAdsConfig)}</div> }
              </div>
              <Pagination className="pagination" {...paginationProps} />
            </div>
            {
              this.state.breakpoint === BREAKPOINTS.mobile ?
                <AdDisplayer
                  newAdsConfig={newPageAdsConfig}
                  adSlot={'mobile-box-1'}
                  googleAdParams={adParams.mobileBox1Params} /> :
                <AdDisplayer
                  newAdsConfig={newPageAdsConfig}
                  adSlot={'leaderboard-bottom'}
                  googleAdParams={adParams.leaderboardBottomParams} />
            }
            <div className="category-selector-container">
              <CategorySelector categories={categories} {...this.props}/>
            </div>
          </div>
        </AdProvider>
      </>
    );
  }
}

BlogHome.propTypes = {
  isLoading: PropTypes.bool,
  articles: PropTypes.object.isRequired,
  pageHorizon: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  maxPages: PropTypes.number.isRequired,
  getPaginationUrl: PropTypes.func.isRequired,
  onPaginationChange: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  seoContent: PropTypes.array,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  adsData: PropTypes.object,
  cookies: PropTypes.instanceOf(Cookies),
  abTestContext: PropTypes.object,
};

BlogHome.contextType = PortalConfigContext;

const mapStateToProps = (state) => {
  return {
    seoContent: get(state.app, 'data.seoContent', [])
  };
};

export default connect(
  mapStateToProps,
  dispatch => bindActionCreators({}, dispatch)
)(injectIntl(BlogHome));
